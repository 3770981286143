import { AuthService } from "../services/AuthService";
import { FunctionService } from "../services/FunctionService";
import { Helpers } from "../services/Helpers";
import { Communication } from "../services/Communication";
export class AppStore {
  readonly authService: AuthService;
  readonly functionService: FunctionService;
  readonly helpers: Helpers;
  public communication: Communication;
  readonly version: string;
  constructor(
    functionService: FunctionService,
    authService: AuthService,
    helpers: Helpers,
    communication: Communication,
    version: string,
  ) {
    this.authService = authService;
    this.functionService = functionService;
    this.helpers = helpers;
    this.version = version;
    this.communication = communication;
  }
}
