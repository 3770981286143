import React, { useState, useEffect } from "react";
import { useStyles } from "../../styling/global/global";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { AppStore } from "../../../stores/AppStore";
import {
  SensorHealthTimelineCount,
  Activity,
} from "../../../services/FunctionService";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  Label,
  BarChart,
  Bar,
} from "recharts";
import Slider from "@material-ui/core/Slider";

interface SensorStatsModalProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  sensor_id_str: string;
  site_id: number;
  sensor_id: number;
  currenttheme: string;
}
const SensorStatsModal: React.FC<SensorStatsModalProps> = ({
  currenttheme,
  sensor_id,
  sensor_id_str,
  site_id,
  open,
  onClose,
  appStore,
}: SensorStatsModalProps) => {
  const classes = useStyles();
  const [activity, setActivity] = useState<Activity | undefined>(undefined);
  const [sensorhealthtimeline, setSensorHealthTimeline] = useState<
    SensorHealthTimelineCount[] | undefined
  >(undefined);
  const [interval, setInterval] = useState<number>(4);
  const handleClose = () => {
    setSensorHealthTimeline(undefined);
    setActivity(undefined);
    onClose();
  };
  useEffect(() => {
    const fetchData = async () => {
      const token = await appStore?.authService.getAuthorisedToken();
      if (token && site_id != 0 && sensor_id_str != "") {
        let now = new Date();
        let hours = convertIntervalToHours(interval);
        const activity = await appStore?.functionService.getActivityForSensors(
          token,
          [sensor_id],
          now.getTime() / 1000 - 3600 * hours,
          hours * 60,
        );
        if (activity.success) {
          setActivity(activity.msg);
        }
        const sensorhealthtimeline =
          await appStore?.functionService.getHealthTimeLineForSensor(
            token,
            site_id,
            sensor_id_str,
            now.getTime() / 1000 - 3600 * hours,
            hours * 60,
          );
        if (sensorhealthtimeline.success) {
          setSensorHealthTimeline(sensorhealthtimeline.msg);
        }
      }
    };
    fetchData();
  }, [open, interval]);

  function convertIntervalToHours(interval: number) {
    let hours = 2;
    if (interval == 8) {
      hours = 4;
    }
    if (interval == 16) {
      hours = 8;
    }
    if (interval == 24) {
      hours = 12;
    }
    if (interval == 42) {
      hours = 24;
    }
    if (interval == 64) {
      hours = 48;
    }
    if (interval == 100) {
      hours = 168;
    }
    if (interval == 148) {
      hours = 336;
    }
    return hours;
  }
  const addZero = (str: string) => {
    if (str.length == 1) {
      str = "0" + str;
    }
    return str;
  };
  const marks = [
    {
      value: 2,
      label: "2H",
    },
    {
      value: 8,
      label: "4H",
    },
    {
      value: 16,
      label: "8H",
    },
    {
      value: 24,
      label: "12H",
    },
    {
      value: 42,
      label: "24H",
    },
    {
      value: 64,
      label: "48H",
    },
    {
      value: 100,
      label: "1 Week",
    },
    {
      value: 148,
      label: "2 Weeks",
    },
  ];
  const handleIntervalChange = (
    event: React.ChangeEvent<any>,
    interval: number | number[],
  ) => {
    setInterval(interval as number);
  };
  var timeseries: React.ReactNode[] = [];
  if (activity) {
    timeseries.push(
      <Typography
        className={
          currenttheme == "dark"
            ? classes.graph_header_popup_dark
            : classes.graph_header_popup
        }
      >
        Sensor Activity
      </Typography>,
    );
    let data = [];
    for (var i = 0; i < activity.inference_counts.length; i++) {
      var time = new Date(activity.inference_counts[i].epoch_msecs);
      var hours = time.getHours();
      var minutes = time.getMinutes();
      var x = addZero(hours.toString()) + ":" + addZero(minutes.toString());
      if (interval > 42) {
        var day = time.getDate();
        var month = time.getMonth() + 1;
        x =
          addZero(day.toString()) +
          "/" +
          addZero(month.toString()) +
          " " +
          addZero(hours.toString()) +
          ":" +
          addZero(minutes.toString());
      }
      var tmp: { [key: string]: any } = {};
      tmp["time"] = x;
      tmp["inferences"] = activity.inference_counts[i].count;
      for (var j = 0; j < activity.alert_counts.length; j++) {
        if (
          activity.inference_counts[i].epoch_msecs ==
          activity.alert_counts[j].epoch_msecs
        )
          tmp["alerts"] = activity.alert_counts[j].count;
      }
      if (!("alerts" in tmp)) {
        tmp["alerts"] = 0;
      }
      data.push(tmp);
    }
    timeseries.push(
      <AreaChart
        width={500}
        height={200}
        data={data}
        margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
      >
        <Legend />
        <Tooltip />
        <XAxis dataKey="time" stroke="#1f3161" tick={{ fontSize: 12 }} />
        <YAxis type="number" stroke="#1f3161" tick={{ fontSize: 12 }} />
        <CartesianGrid stroke="#f5f5f5" />
        <Area
          dataKey="alerts"
          type="monotone"
          stroke="#ee4623"
          fill="#ee4623"
        />
        <Area
          dataKey="inferences"
          type="monotone"
          stroke="#1f3161"
          fill="#1f3161"
        />
      </AreaChart>,
    );
  }

  if (sensorhealthtimeline) {
    let sensorhealthtimelinedata = [];
    for (var i = 0; i < sensorhealthtimeline.length; i++) {
      var time = new Date(sensorhealthtimeline[i].epoch_msecs);
      var hours = time.getHours();
      var minutes = time.getMinutes();
      var x = addZero(hours.toString()) + ":" + addZero(minutes.toString());
      if (interval > 42) {
        var day = time.getDate();
        var month = time.getMonth() + 1;
        x =
          addZero(day.toString()) +
          "/" +
          addZero(month.toString()) +
          " " +
          addZero(hours.toString()) +
          ":" +
          addZero(minutes.toString());
      }
      var tmp: { [key: string]: any } = {};
      tmp["time"] = x;
      if (sensor_id_str in sensorhealthtimeline[i].sensors) {
        if ("ok" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
          tmp["healthy"] =
            (sensorhealthtimeline[i].sensors[sensor_id_str]["ok"] /
              sensorhealthtimeline[i].doc_count) *
            sensorhealthtimeline[i].sensor_count;
        } else {
          tmp["healthy"] = 0;
        }
        if ("warning" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
          tmp["degraded"] =
            (sensorhealthtimeline[i].sensors[sensor_id_str]["warning"] /
              sensorhealthtimeline[i].doc_count) *
            sensorhealthtimeline[i].sensor_count;
        } else {
          tmp["degraded"] = 0;
        }
        if ("error" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
          tmp["unhealthy"] =
            (sensorhealthtimeline[i].sensors[sensor_id_str]["error"] /
              sensorhealthtimeline[i].doc_count) *
            sensorhealthtimeline[i].sensor_count;
        } else {
          tmp["unhealthy"] = 0;
        }
        if ("unknown" in sensorhealthtimeline[i].sensors[sensor_id_str]) {
          tmp["unknown"] =
            (sensorhealthtimeline[i].sensors[sensor_id_str]["unknown"] /
              sensorhealthtimeline[i].doc_count) *
            sensorhealthtimeline[i].sensor_count;
        } else {
          tmp["unknown"] = 0;
        }
        sensorhealthtimelinedata.push(tmp);
      }
    }
    timeseries.push(
      <Typography
        className={
          currenttheme == "dark"
            ? classes.graph_header_popup_dark
            : classes.graph_header_popup
        }
      >
        Sensor Health
      </Typography>,
    );
    timeseries.push(
      <BarChart
        width={500}
        height={200}
        data={sensorhealthtimelinedata}
        margin={{ top: 2, bottom: 2, left: 2, right: 2 }}
      >
        <Legend />
        <Tooltip />
        <Label value="Health" position="outside" />
        <YAxis
          type="number"
          domain={["dataMin", "dataMax"]}
          allowDecimals={false}
          stroke="#1f3161"
          tick={{ fontSize: 12 }}
        />
        <XAxis dataKey="time" stroke="#1f3161" tick={{ fontSize: 12 }} />
        <CartesianGrid stroke="#f5f5f5" />
        <Bar stackId="a" dataKey="healthy" fill="#1f3161" />
        <Bar stackId="a" dataKey="degraded" fill="#b4d5fa" />
        <Bar stackId="a" dataKey="unhealthy" fill="#ee4623" />
        <Bar stackId="a" dataKey="unknown" fill="#D3D3D3" />
      </BarChart>,
    );

    timeseries.push(
      <div className={classes.interval_selector}>
        <Slider
          value={interval}
          step={null}
          marks={marks}
          min={2}
          max={148}
          onChange={(event: React.ChangeEvent<any>, x: number | number[]) =>
            handleIntervalChange(event, x)
          }
        />
      </div>,
    );
  }

  if (sensorhealthtimeline && activity) {
    if (currenttheme == "dark") {
      return (
        <Dialog
          onClose={handleClose}
          open={open}
          PaperProps={{
            style: {
              background:
                "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
              boxShadow:
                "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
            },
          }}
        >
          <DialogTitle
            style={{ width: 600 }}
            className={
              currenttheme == "dark" ? classes.icon_white : classes.icon_blue
            }
          >
            Sensor {sensor_id_str}
          </DialogTitle>

          {timeseries}

          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>OK</span>
            </Button>
          </div>
        </Dialog>
      );
    } else {
      return (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle
            style={{ width: 600 }}
            className={
              currenttheme == "dark" ? classes.icon_white : classes.icon_blue
            }
          >
            Sensor {sensor_id_str}
          </DialogTitle>

          {timeseries}

          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>OK</span>
            </Button>
          </div>
        </Dialog>
      );
    }
  } else {
    if (currenttheme == "dark") {
      return (
        <Dialog
          onClose={handleClose}
          className={
            currenttheme == "dark" ? classes.darkleft : classes.lightleft
          }
          open={open}
          PaperProps={{
            style: {
              background:
                "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
              boxShadow:
                "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
            },
          }}
        >
          <DialogTitle
            className={
              currenttheme == "dark" ? classes.icon_white : classes.icon_blue
            }
          >
            Sensor {sensor_id_str}
          </DialogTitle>
          <div className="wait-container">
            <svg width="48px" height="48px" viewBox="0 0 128 128">
              <g>
                <linearGradient id="linear-gradient">
                  <stop offset="0%" stopColor="#ffffff" />
                  <stop offset="100%" stopColor="#22305f" />
                </linearGradient>
                <path
                  d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                  fill="url(#linear-gradient)"
                  fill-rule="evenodd"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1080ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>OK</span>
            </Button>
          </div>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          onClose={handleClose}
          className={
            currenttheme == "dark" ? classes.darkleft : classes.lightleft
          }
          open={open}
        >
          <DialogTitle
            className={
              currenttheme == "dark" ? classes.icon_white : classes.icon_blue
            }
          >
            Sensor {sensor_id_str}
          </DialogTitle>
          <div className="wait-container">
            <svg width="48px" height="48px" viewBox="0 0 128 128">
              <g>
                <linearGradient id="linear-gradient">
                  <stop offset="0%" stopColor="#ffffff" />
                  <stop offset="100%" stopColor="#22305f" />
                </linearGradient>
                <path
                  d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                  fill="url(#linear-gradient)"
                  fill-rule="evenodd"
                />
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 64 64"
                  to="360 64 64"
                  dur="1080ms"
                  repeatCount="indefinite"
                ></animateTransform>
              </g>
            </svg>
          </div>
          <div className={classes.button_container}>
            <Button
              variant="contained"
              className={classes.cancel_button}
              onClick={handleClose}
            >
              <span className={classes.bold_text}>OK</span>
            </Button>
          </div>
        </Dialog>
      );
    }
  }
};

export default SensorStatsModal;
