import React from "react";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { MainMenu } from "../../../services/FunctionService";
import moment from "moment-timezone";
interface SettingsMainDrawerProps {
  appStore?: AppStore;
  currentstrategy: string;
  currenttheme: string;
  handleCurrentStategyChange: (strategy: string) => void;
  handleCurrentThemeChange: (strategy: string) => void;
  changeMainMenu: (d: MainMenu) => void;
  mainMenu: MainMenu;
}
const SettingsMainDrawer: React.FC<SettingsMainDrawerProps> = ({
  appStore,
  changeMainMenu,
  mainMenu,
  currenttheme,
  handleCurrentThemeChange,
  currentstrategy,
  handleCurrentStategyChange,
}: SettingsMainDrawerProps) => {
  const classes = useStyles();
  const [strategy, setStrategy] = React.useState(currentstrategy);
  const [theme, setTheme] = React.useState(currenttheme);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setStrategy(event.target.value);
  };
  const handleThemeChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setTheme(event.target.value);
  };
  const handleSaveUserSettings = async (strategy: string, theme: string) => {
    let token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      var userSetting = await appStore?.functionService.setUserSettings(
        token,
        strategy,
        theme,
      );
    }
  };

  const updateSettings = () => {
    handleCurrentStategyChange(strategy);
    handleCurrentThemeChange(theme);
    handleSaveUserSettings(strategy, theme);
    mainMenu.dashboard = false;
    mainMenu.reports = false;
    mainMenu.settings = false;
    mainMenu.navigator = true;
    changeMainMenu(mainMenu);
  };
  return (
    <div className={theme == "dark" ? classes.content_dark : classes.content}>
      <div className={classes.space}></div>
      <Typography
        className={
          theme == "dark"
            ? classes.bold_text_large_white
            : classes.bold_text_large
        }
      >
        Settings
      </Typography>
      <div className={classes.space_smaller}></div>
      <Divider />
      <div className={classes.space_smallest}></div>
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          className={
            theme == "dark" ? classes.bold_text_white : classes.bold_text
          }
        >
          Move to next event strategy:
        </FormLabel>
        <div className={classes.space_smallest}></div>
        <RadioGroup
          aria-label="strategy"
          name="strategy1"
          value={strategy}
          onChange={handleChange}
        >
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="off"
            control={<Radio />}
            label="Off"
          />
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="oldest"
            control={<Radio />}
            label="Oldest Alert"
          />
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="newest"
            control={<Radio />}
            label="Newest Alert"
          />
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="older"
            control={<Radio />}
            label="Older Alert"
          />
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="newer"
            control={<Radio />}
            label="Newer Alert"
          />
        </RadioGroup>
      </FormControl>
      <Divider />
      <div className={classes.space_smallest}></div>
      <FormControl component="fieldset">
        <FormLabel
          className={
            theme == "dark" ? classes.bold_text_white : classes.bold_text
          }
          component="legend"
        >
          Theme:
        </FormLabel>
        <div className={classes.space_smallest}></div>
        <RadioGroup
          aria-label="theme"
          name="theme"
          value={theme}
          onChange={handleThemeChange}
        >
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="dark"
            control={<Radio />}
            label="Dark"
          />
          <FormControlLabel
            className={
              theme == "dark" ? classes.bold_text_white : classes.bold_text
            }
            value="light"
            control={<Radio />}
            label="Light"
          />
        </RadioGroup>
      </FormControl>
      <div className={classes.button_container_small}>
        <Button
          variant="contained"
          size="small"
          onClick={() => updateSettings()}
        >
          Done
        </Button>
        &nbsp;&nbsp;
      </div>
    </div>
  );
};

export default SettingsMainDrawer;
