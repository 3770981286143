export const VersionChecking = (
  frontValue: string,
  backValue: string,
): boolean => {
  var frontVarThird = parseInt(frontValue.split(".")[2]);
  var frontVarSecond = parseInt(frontValue.split(".")[1]);
  var frontVarFirst = parseInt(frontValue.split(".")[0]);
  var backVarThird = parseInt(backValue.split(".")[2]);
  var backVarSecond = parseInt(backValue.split(".")[1]);
  var backVarfirst = parseInt(backValue.split(".")[0]);
  if (frontVarFirst > backVarfirst) {
    return true;
  } else if (frontVarFirst === backVarfirst) {
    if (frontVarSecond > backVarSecond) {
      return true;
    } else if (frontVarSecond === backVarSecond) {
      if (frontVarThird >= backVarThird) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
