import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import {
  Alert as AlertBox,
  audioRecording,
  audioRecordingResult,
  UpdateResult,
} from "../../../../services/FunctionService";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

interface TalkbackPreCannedProps {
  appStore?: AppStore;
  alert: AlertBox | undefined;
  onClose: () => void;
}
const TalkbackPreCanned = ({
  appStore,
  alert,
  onClose,
}: TalkbackPreCannedProps) => {
  const classes = useStyles();
  const [preCannedRecordings, setPreCannedRecordings] = useState<
    audioRecording[]
  >([]);
  const [resultFailure, setResultFailure] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [preCannedId, setPreCannedId] = useState<number>(0);

  const fetchAudios = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const res: audioRecordingResult =
        await appStore?.functionService.getPreCannedAudio(
          token,
          alert!.sensor_id_str,
        );
      if (res.success) {
        setLoading(false);
        setPreCannedRecordings(res.recordings);
        setPreCannedId(res.recordings[0].id);
      } else {
        setResultFailure(true);
        setFailMessage("failed to fetch pre-canned audios");
        setLoading(false);
      }
    }
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let id = event.target.value as number;
    setPreCannedId(id);
  };
  const postAudios = async (audio_number: number) => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const res: UpdateResult =
        await appStore?.functionService.postPreCannedAudio(
          token,
          alert!.sensor_id_str,
          audio_number,
        );
      if (res.success) {
        setResultFailure(true);
        setFailMessage("audio broadcast successful");
        setLoading(false);
      } else {
        setResultFailure(true);
        setFailMessage("failed to broadcast audio");
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchAudios();
  }, [alert]);

  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
        setFailMessage("");
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [resultFailure]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {preCannedRecordings && (
            <FormControl
              variant="outlined"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
                marginBottom: 10,
              }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Recordings
              </InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={preCannedId}
                onChange={handleChange}
                label="Age"
              >
                {preCannedRecordings.map((recording, index) => {
                  return (
                    <MenuItem
                      key={recording.id}
                      value={recording.id}
                      style={{ whiteSpace: "normal" }}
                    >
                      <div
                        style={{
                          width: 400,
                          marginBottom: 2,
                        }}
                      >
                        <span style={{ wordWrap: "normal", width: "100%" }}>
                          {recording.description}
                        </span>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
          {resultFailure && (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity={
                  failMessage === "audio broadcast successful"
                    ? "success"
                    : "error"
                }
                onClose={() => {
                  setResultFailure(false);
                  setFailMessage("");
                }}
              >
                {failMessage}
              </Alert>
            </Box>
          )}
          <div
            className={classes.button_container_small}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              className={classes.menu_button}
              style={{ marginRight: 5 }}
              onClick={() => onClose()}
            >
              <span className={classes.bold_text}>close</span>
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              variant="contained"
              className={classes.menu_button}
              onClick={() => postAudios(preCannedId)}
            >
              <span className={classes.bold_text}>broadcast</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TalkbackPreCanned;
