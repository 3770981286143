import React, { useState, useRef } from "react";

import MainDrawer from "../MainDrawer/MainDrawer";
import { AppStore } from "../../../stores/AppStore";
import { MainMenu } from "../../../services/FunctionService";
import { Helpers } from "../../../services/Helpers";
import { useStyles } from "../../styling/global/global";
import { Communication } from "../../../services/Communication";
interface MockEvent {
  (event: Event): any;
}
interface HomePageProps {
  appStore?: AppStore;
  authhandler: MockEvent;
  helpers: Helpers;
  communication: Communication;
}
class impMainMenu implements MainMenu {
  navigator: boolean = true;
  reports: boolean = false;
  dashboard: boolean = false;
  settings: boolean = false;
}
const HomePage: React.FC<HomePageProps> = ({
  communication,
  appStore,
  authhandler,
  helpers,
}: HomePageProps) => {
  const classes = useStyles();
  const [mainMenu, setMainMenu] = React.useState(new impMainMenu());

  const [refresh, RefreshNow] = React.useState(0);
  const maindrawer = useRef(null);
  const changeMainMenu = (newMainMenu: MainMenu) => {
    setMainMenu(newMainMenu);
    RefreshNow(new Date().getTime());
  };

  return (
    <div className={classes.root}>
      <MainDrawer
        communication={communication}
        helpers={helpers}
        appStore={appStore}
        mainMenu={mainMenu}
        changeMainMenu={changeMainMenu}
        authhandler={authhandler}
      ></MainDrawer>
    </div>
  );
};

export default HomePage;
