import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket";
import { AuthService } from "./AuthService";
//This is the socket class that looks after communication between connected agents logged into same sites/channels

export class Communication {
  public client: W3CWebSocket | undefined;
  public connected = false;
  public message = "";
  public receivedMessage!: (arg0: IMessageEvent) => void;
  public authService: AuthService;
  private serverurl = "";

  connect() {
    this.client = new W3CWebSocket(this.serverurl);
    this.client.onopen = async () => {
      console.log("WebSocket Client Connected");
      this.connected = true;
      if (
        this.authService.isCurrentlyAuthorised() &&
        this.client!.readyState === this.client!.OPEN
      ) {
        let msg: { [key: string]: any } = {};
        msg["type"] = "auth";
        msg["payload"] = await this.authService.getAuthorisedToken();
        this.client!.send(JSON.stringify(msg));
      }
    };
    this.client.onmessage = (message) => {
      if (this.receivedMessage) {
        this.receivedMessage(message);
      }
    };
    this.client.onclose = (event) => {
      this.connected = false;
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`,
        );
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        //console.log("[close] Connection died: ");
      }
    };
    this.client.onerror = (error) => {
      this.connected = false;
      console.log(`[error] ${error.message}`);
    };
  }

  disconnect() {
    this.client?.close();
    this.client = undefined;
    this.connected = false;
    console.log("disconnected called!");
  }
  constructor(authService: AuthService, url: string) {
    this.authService = authService;
    // if (url.includes("staging") || url.includes("local")) {
    //   this.serverurl = "wss://alerting_notification.staging.deepalert.ai";
    // } else {
    //   this.serverurl = "wss://alerting_notification.deepalert.ai";
    // }
    this.serverurl = "wss://" + url.split("/")[2] + "/alerting-notification";
    this.connect();
  }
}
