import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { Theme, makeStyles } from "@material-ui/core/styles";

import {
  Alert as AlertBox,
  UpdateResult,
} from "../../../../services/FunctionService";
import { useStyles } from "../../../styling/global/global";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../spinner/LoadingSpinner";

const customStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
    // "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //   borderColor: "red",
    // },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "purple",
    },
    "& .MuiOutlinedInput-input": {
      color: "green",
    },
    // "&:hover .MuiOutlinedInput-input": {
    //   color: "red",
    // },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "purple",
    },
    "& .MuiInputLabel-outlined": {
      color: "green",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "red",
    },
    // "& .MuiInputLabel-outlined.Mui-focused": {
    //   color: "purple",
    // },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "red",
      },
    },
  },
  bold_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
}));

interface TextToSpeechProps {
  appStore?: AppStore;
  alert: AlertBox | undefined;
  onClose: () => void;
}
const TextToSpeech: React.FC<TextToSpeechProps> = ({
  appStore,
  alert,
  onClose,
}: TextToSpeechProps) => {
  const classes = useStyles();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [resultFailure, setResultFailure] = useState(false);
  const [failMessage, setFailMessage] = useState("");

  function handleTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.target.value);
  }
  const handleSaveText = async () => {
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      setLoading(true);
      const res: UpdateResult =
        await appStore?.functionService.talkbackTextToSpeech(
          token,
          alert!.sensor_id_str,
          text,
        );
      if (res.success) {
        setResultFailure(true);
        setFailMessage("audio message dispatched");
        setLoading(false);
      } else {
        setResultFailure(true);
        setFailMessage(res.msg);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
        setFailMessage("");
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [resultFailure]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            style={{ width: "50%" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="text"
            placeholder="text speech here"
            autoFocus
            // onFocus={}
            value={text}
            onChange={handleTextChange}
            className={classes.talkbackRootBackground}
            InputProps={{
              className: classes.bold_text_text_field,
              classes: {
                root: classes.bold_text_text_field,
                notchedOutline: classes.bold_text_text_field,
              },
              style: { borderWidth: 3, borderColor: "red" },
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text_text_field,
              },
              style: { color: "#ebe7dd" },
            }}
          />
          {resultFailure && (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity={
                  failMessage === "audio message dispatched"
                    ? "success"
                    : "error"
                }
                onClose={() => {
                  setResultFailure(false);
                  setFailMessage("");
                }}
              >
                {failMessage}
              </Alert>
            </Box>
          )}
          <div
            className={classes.button_container_small}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              className={classes.menu_button}
              style={{ marginRight: 5 }}
              onClick={() => onClose()}
            >
              <span className={classes.bold_text}>close</span>
            </Button>
            <Button
              style={{ marginLeft: 5 }}
              variant="contained"
              className={classes.menu_button}
              onClick={handleSaveText}
            >
              <span className={classes.bold_text}>broadcast</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextToSpeech;
