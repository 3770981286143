import { Theme, makeStyles } from "@material-ui/core/styles";
const rightdrawerWidth = 300;
const leftdrawerWidth = 300;

export const useStyles = makeStyles((theme: Theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    display: "flex",
  },
  muteInputs: {
    display: "flex",
    margin: 40,
  },
  selectNotes: {
    width: "80%",
  },
  muteInput: {
    width: 80,
    borderWidth: 1,
    textAlign: "center",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    background: "inherit",
    borderColor: "grey",
    outline: "none",
    color: "grey",
    fontSize: 13,
    // fontWeight: 'bold'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    maxHeight: 70,
  },
  leftAppBar: {
    height: 60,
    width: leftdrawerWidth,
  },
  login: {
    minHeight: 2000,
    width: "100%",
    padding: 50,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  dark: {
    minHeight: 2000,
    width: "100%",
    paddingLeft: 150,
    paddingTop: 80,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  dark_card: {
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    width: "99%",
    padding: 15,
    minHeight: 360,
  },
  darkleft: {
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  lightleft: {
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  expansionpaneldetails: {
    background: "#ededed",
  },
  mainexpansionpaneldetails: {},
  mainexpansionpaneldetails_dark: {
    background: "#ededed",
  },
  expansionpanelsummary: {
    background: "#1f3161",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  mainexpansionpanelsummary: {
    background: "#1f3161",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  darkleftbackground: {
    minHeight: 2000,
    width: "100%",
    paddingLeft: 1,
    paddingTop: 1,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
  },
  lightleftbackground: {
    minHeight: 2000,
    width: "100%",
    paddingLeft: 1,
    paddingTop: 1,
    background: "#FFFFFF",
    boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
  },
  darkrightbackground: {
    minHeight: 2000,
    width: "100%",
    paddingLeft: 1,
    paddingTop: 1,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  lightrightbackground: {
    minHeight: 2000,
    width: "100%",
    paddingLeft: 1,
    paddingTop: 1,
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  light: {
    minHeight: 2000,
    width: "100%",
    paddingLeft: 150,
    paddingTop: 80,
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
  },
  light_card: {
    background: "#FFFFFF",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    width: "95%",
    padding: 35,
    minHeight: 360,
  },
  bold_text_white_blue_background: {
    color: "#FFFFFF",
    backgroundColor: "#4773B8 100%",
    borderColor: "#FFFFFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  rightdrawer: {
    width: rightdrawerWidth,
    flexShrink: 0,
  },

  rightdrawerPaper: {
    width: rightdrawerWidth,
  },
  leftdrawer: {
    width: leftdrawerWidth,
    flexShrink: 0,
    overflow: "hidden",
  },
  leftdrawerPaper: {
    width: leftdrawerWidth,
  },

  drawerContainer: {
    marginTop: -5,
    overflowY: "scroll",
    overflowX: "hidden",
    maxWidth: leftdrawerWidth,
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: 150,
  },
  content_dark: {
    flexGrow: 1,
    background:
      "linear-gradient(90deg, #4773B8 0%, rgba(57, 72, 158, 0.98) 100%)",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.12)",
    paddingLeft: 150,
    height: 2000,
  },
  logo: {
    paddingLeft: 20,
    float: "left",
    width: 138,
    height: 32,
  },
  leftdrawerwidth: {
    width: leftdrawerWidth,
  },
  expansionbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: leftdrawerWidth,
    background: "#1f3161",
    marginTop: 5,
  },
  expansioncontent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: leftdrawerWidth - 50,
    background: "#ffffff",
  },
  mainexpansionbar: {
    width: "95%",
  },
  toolbaritems: {
    justifyContent: "space-between",
  },
  channel_card: {
    margin: 5,
  },
  alert_card_unreviewed: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: 12,
    color: "#1f3161",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#b4d5fa",
    margin: 3,
  },
  alert_card_done: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: 12,
    color: "#15325F",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#808185",
    margin: 3,
  },
  alert_card_selected: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    margin: 3,
  },
  alert_card_escalated: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#ee4623",
    margin: 3,
  },
  alert_card_resolved: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#448ebb",
    margin: 3,
  },
  review_by_other: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#C0C0C0",
    margin: 3,
  },
  updated_by_other: {
    cursor: "pointer",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    lineHeight: "14px",
    overflow: "hidden",
    backgroundColor: "#D4AF37",
    margin: 3,
  },
  health_red: {
    marginLeft: 120,
    color: "#ee4623",
  },
  health_green: {
    marginLeft: 120,
    color: "#3f54a5",
  },
  health_amber: {
    marginLeft: 120,
    color: "#ffbf00",
  },
  health_unknown: {
    marginLeft: 120,
  },
  checkright: {
    float: "right",
  },
  floatleft: {
    float: "left",
  },
  floatright: {
    float: "right",
    marginRight: 60,
  },
  sidemenu: {
    marginLeft: -130,
  },
  sidemenu_channel: {
    marginLeft: -60,
  },
  sidemenu_camera: {
    marginLeft: -70,
  },
  popmenu: {
    zIndex: 1280,
  },
  cardcontent: {
    marginLeft: 3,
    padding: 0,
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  interval_selector: {
    height: 40,
    marginLeft: 50,
    marginRight: 35,
    marginBottom: 20,
  },
  full_label: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    width: "200px",
  },
  site_label: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    width: "100px",
    marginTop: 10,
  },
  textarea: {
    width: "90%",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#15325F",
    fontSize: 12,
    marginLeft: 20,
  },
  //TEXT
  logo_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    marginTop: -25,
    marginLeft: 120,
  },
  button_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    color: "#FFFFFF",
    marginTop: 20,
    marginBottom: 20,
    background: "#4d8fff",
    width: 200,
  },
  bold_text_large: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#15325F",
  },
  bold_text_large_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#FFFFFF",
  },
  bold_text_large_red: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
    color: "#FF0000",
  },
  bold_text_large_margin: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    marginLeft: 20,
  },
  bold_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  bold_text_text_field: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    borderColor: "black",
    borderWidth: 2,
  },
  bold_text_margin: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    marginLeft: 20,
  },
  bold_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
  },
  bold_text_red: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#ee4623",
    width: 300,
  },
  standard_text: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#15325F",
    fontSize: 12,
  },
  standard_text_white: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#FFFFFF",
    fontSize: 12,
  },
  medium_text_bold: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 10,
    color: "#15325F",
  },
  smaller_text_bold: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 8,
    color: "#15325F",
  },
  center_text: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  //EXPANSION DRAWERS
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  header_icon: {
    marginTop: 8,
    marginLeft: 10,
    display: "flex",
    color: "#15325F",
    alignItems: "center",
  },
  header_text: {
    display: "flex",
    marginTop: 8,
    fontFamily: "Open Sans",
    height: 53,
    alignItems: "center",
    fontSize: 12,
    fontWeight: "bold",
    width: "100%",
    paddingLeft: 12,
    color: "#15325F",
    justifyContent: "start",
  },
  sub_header: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  graph_header: {
    marginTop: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  graph_header_dark: {
    marginTop: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
  },
  graph_header_popup: {
    marginTop: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    marginLeft: 220,
  },
  graph_header_popup_dark: {
    marginTop: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    marginLeft: 220,
  },
  pie_header: {
    marginTop: 3,
    paddingLeft: 20,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    width: 200,
  },
  pie_header_dark: {
    marginTop: 3,
    paddingLeft: 20,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    width: 200,
  },
  title_icon: {
    marginTop: 3,
    marginLeft: 10,
    display: "flex",
    color: "#15325F",
    alignItems: "center",
  },
  expansion_shelf: {
    cursor: "grab",
    display: "flex",
    width: "100%",
    height: 15,
    justifyContent: "space-between",
  },
  rule_name: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "35%",
    justifyContent: "flex-start",
  },
  rule_container: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "60%",
    justifyContent: "space-around",
  },
  rule_menu: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "5%",
    justifyContent: "flex-end",
  },
  site_name: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "100%",
    justifyContent: "flex-start",
  },
  channel: {
    marginLeft: -40,
  },
  shelf_name: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "20%",
    justifyContent: "flex-start",
  },
  shelf_container: {
    display: "flex",
    alignItems: "flex-start",
    color: "#6D809D",
    width: "60%",
    justifyContent: "space-evenly",
  },
  shelf_item: {
    display: "flex",
    alignItems: "flex-start",
    color: "#6D809D",
    justifyContent: "space-evenly",
  },
  shelf_item_top: {
    display: "flex",
    alignItems: "flex-start",
    color: "#6D809D",
    justifyContent: "space-evenly",
    paddingTop: 20,
  },
  shelf_image: {
    marginTop: "-15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  shelf_menu: {
    display: "flex",
    alignItems: "center",
    color: "#6D809D",
    width: "20%",
    justifyContent: "flex-end",
  },
  shelf_icon: {
    paddingRight: 20,
  },
  card_item: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-evenly",
  },
  product_icon: {
    height: 45,
    marginTop: -15,
  },
  icon_white: {
    color: "#FFFFFF",
  },
  icon_blue: {
    color: "#15325F",
  },
  //OTHER AREAS
  note_dark: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    width: "95%",
    background: "rgba(57, 72, 158, 0.58)",
  },
  note_light: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    width: "95%",
    background: "#FFFFFF",
  },
  login_container: {
    width: 480,
    height: 520,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 35,
    background: "#1f316133",
    borderRadius: 10,
  },
  top_container: {
    margin: 8,
    marginLeft: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  contollers: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  middle_container: {
    margin: 4,
    marginLeft: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 400,
  },
  menu_container: {
    padding: 10,
    paddingTop: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    boxShadow: "0 2px 8px rgba(0,0,0,0.3)",
    width: 450,
    borderRadius: 24,
    borderBottom: 0,
    backgroundColor: "#f6f6f6",
  },
  smoothen_container: {
    border: "1px solid white",
    borderRadius: 24,
  },
  message_container: {
    padding: 20,
    width: 300,
  },
  top_link: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    cursor: "pointer",
    marginLeft: 20,
  },
  pointer: {
    cursor: "pointer",
    marginLeft: 5,
  },
  icon_pointer: {
    cursor: "pointer",
  },
  button_container: {
    margin: 12,
    display: "flex",
    justifyContent: "space-around",
  },
  button_container_small: {
    margin: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexShrink: 1,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: "white !important",
  },
  button_container_small_display: {
    margin: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexShrink: 1,
    paddingTop: 5,
  },
  button_shrink: {
    flexShrink: 1,
  },
  card_container: {
    display: "flex",
    padding: 20,
    justifyContent: "space-around",
    marginLeft: -40,
  },
  main_drawer_container: {
    marginLeft: leftdrawerWidth - 100,
  },
  modal_dialog: {
    margin: "auto",
    maxWidth: "md",
    fullWidth: true,
  },
  medium_panel: {
    margin: "auto",
    width: "60%",
  },
  main_drawer_header: {
    display: "flex",
    marginTop: 25,
  },
  placeholder: {
    display: "flex",
    marginTop: 25,
    marginLeft: "auto",
    marginRight: "auto",
  },
  //OTHER OBJECTS:
  image_component: {
    margin: "auto",
    padding: 15,
  },
  form_component: {
    padding: 15,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  form_component_icons: {
    padding: 5,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    marginLeft: 75,
    marginBottom: 20,
  },
  form_component_toggles: {
    height: 45,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    width: 360,
    label: "#FFFFFF",
    outlineColor: "#FFFFFF",
    outlineWidth: "1px",
    outlineStyle: "solid",
    margin: "2px",
  },
  toggle_button: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#FFFFFF",
    label: "#FFFFFF",
  },

  form_component_checkbox: {
    padding: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
  },
  save_button: {
    width: 85,
  },
  talkback_menu_button: {
    width: 100,
    height: 35,
    borderWidth: 1,
    borderColor: "rgba(21, 50, 95, 0.59)",
    borderStyle: "solid",
    marginBottom: 20,
    borderRadius: 20,
    color: "#ffffff",
    "&:hover": {
      // transform: 'scale(1.1)',
      backgroundColor: "#eeeeee",
      borderColor: "#f6f6f6",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
    },
  },
  talkbackRoot: {
    width: "20%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  talkbackRootBackground: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#15325F",
    borderColor: "black",
    borderWidth: 2,
    backgroundColor: theme.palette.background.paper,
  },
  menu_button: {
    width: 160,
    height: 35,
    borderWidth: 1,
    borderColor: "rgba(21, 50, 95, 0.59)",
    borderStyle: "solid",
    marginBottom: 20,
    borderRadius: 20,
    color: "#ffffff",
    "&:hover": {
      // transform: 'scale(1.1)',
      backgroundColor: "#eeeeee",
      borderColor: "#f6f6f6",
      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.3)",
    },
  },
  cancel_button: {
    width: 120,
    marginBottom: 20,
    borderRadius: 20,
  },
  mute_button: {
    width: 120,
    marginBottom: 20,
    borderRadius: 20,
    marginLeft: 10,
    backgroundColor: "#15325F",
    color: "white",
    "&:hover": {
      backgroundColor: "#0d47a1",
      borderColor: "#ffff",
    },
  },
  confirm_button: {
    width: 85,
    height: 35,
  },
  //TIME SCHEDULERS
  schedule_card: {
    height: 250,
    marginBottom: 10,
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },

  add_schedule: {
    paddingLeft: 45,
    marginTop: -35,
  },
  close_icon: {
    cursor: "pointer",
    float: "right",
    marginTop: "5px",
    width: "20px",
    fill: "#15325F",
  },
  space: {
    height: 60,
    margin: 4,
  },
  space_smaller: {
    height: 30,
    margin: 4,
  },
  space_smallest: {
    height: 3,
    margin: 1,
  },
  space_horisontal: {
    width: 20,
    margin: 4,
  },
  center: {
    margin: "auto",
    display: "block",
  },
  svg_margin: {
    marginLeft: 40,
    marginTop: 80,
  },
  wait_container: {
    margin: "auto",
    display: "block",
  },
  hidden: {
    display: "none",
  },
  red: {
    color: "#ee4623",
  },
  green: {
    color: "#3f54a5",
  },
  amber: {
    color: "#b4d5fa",
  },
}));
