import { FunctionService, AuthResult, Token } from "./FunctionService";

// this class manages the logged in status and visible sites, channels, sensors and user roles.

export class AuthService {
  public handler: ((ev: Event) => void) | null;
  private message: string;
  private isAuthorised: boolean;
  private functionService: FunctionService | null;
  private firstname: string;
  private lastname: string;
  private username: string;
  private user_id: number;
  private channels: { [key: string]: { [key: string]: any } };
  private channel_ids: number[];
  private channel_roles: { [key: string]: string[] };
  private channel_sites: { [key: string]: { [key: string]: any } };
  private channel_sensors: { [key: string]: { [key: string]: any } };
  private frontel_site_details: { [key: number]: { [key: string]: string } };
  private frontel_devices: { [key: number]: { [key: string]: string } };

  async signIn(email: string, password: string): Promise<boolean> {
    try {
      var authtokens = await this.functionService?.authenticateUser(
        email,
        password,
      );
      console.log("authtokens: ", authtokens);
      if (authtokens) {
        if (authtokens.success) {
          this.message = "Successful Login!";
          localStorage.setItem("access_token", (await authtokens).access_token);
          this.decodeAndStore(localStorage.getItem("access_token")!);
          let refresh_token = (await authtokens).refresh_token;
          if (refresh_token) {
            this.isAuthorised = true;
            localStorage.setItem(
              "refresh_token",
              (await authtokens).refresh_token,
            );
          }
        } else {
          if (authtokens.code === 401) {
            this.isAuthorised = false;
            this.message = authtokens.msg || "Invalid username or password!";
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
          } else if (authtokens.code === 404) {
            this.isAuthorised = false;
            this.message =
              authtokens.msg ||
              "Login credentials are valid but not configured to view site alerting - please contact your DeepAlert system administrator!";
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
          } else {
            this.isAuthorised = false;
            this.message =
              authtokens.msg || "Someting went wrong, please try again later!";
            localStorage.setItem("access_token", "");
            localStorage.setItem("refresh_token", "");
          }
        }
      }
      return true;
    } catch (err) {
      this.message = "Service unavaiable at present. Please try again later.";
      return false;
    }
  }

  async freshSignIn(email: string, password: string): Promise<boolean> {
    try {
      var authtokens = this.functionService?.freshAuthenticateUser(
        email,
        password,
      );
      if (authtokens) {
        if ((await authtokens).success) {
          this.isAuthorised = true;
          this.message = "Successful Login!";
          localStorage.setItem("access_token", (await authtokens).access_token);
        } else {
          this.isAuthorised = false;
          this.message = "Invalid username or password!";
        }
      }
      return true;
    } catch (err) {
      return false;
    }
  }

  signOut() {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    this.isAuthorised = false;
    if (refresh_token) {
      let result = this.functionService?.removeRefreshToken(refresh_token);
    }
    if (access_token) {
      let result = this.functionService?.removeAccessToken(access_token);
    }
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
    if (this.handler) {
      var ev: Event;
      ev = new Event("authupdate");
      this.handler!(ev);
    }
  }

  setHandler = (handler: (ev: Event) => void) => {
    this.handler = handler;
  };
  isCurrentlyAuthorised = () => {
    return this.isAuthorised;
  };

  getAuthorisedMessage = () => {
    return this.message;
  };

  getChannels = () => {
    return this.channels;
  };
  setChannels = (_channels: { [key: string]: { [key: string]: any } }) => {
    this.channels = _channels;
  };
  getChannel = (alert_channel_id: number) => {
    let channel = this.channels[alert_channel_id];
    return channel;
  };
  getChannelSites = () => {
    return this.channel_sites;
  };
  setChannelSites = (_channel_sites: {
    [key: string]: { [key: string]: any };
  }) => {
    this.channel_sites = _channel_sites;
  };
  getChannelSensors = () => {
    return this.channel_sensors;
  };
  setChannelSensors = (_channel_sensors: {
    [key: string]: { [key: string]: any };
  }) => {
    this.channel_sensors = _channel_sensors;
  };
  getChannelIds = () => {
    return this.channel_ids;
  };
  setChannelIds = (_channel_ids: number[]) => {
    this.channel_ids = _channel_ids;
  };
  getFrontelSiteDetails = () => {
    return this.frontel_site_details;
  };
  setFrontelSiteDetails = (_frontel_site_details: {
    [key: number]: { [key: string]: string };
  }) => {
    this.frontel_site_details = _frontel_site_details;
  };
  getFrontelDevices = () => {
    return this.frontel_devices;
  };
  setFrontelDevices = (_frontel_devices: {
    [key: number]: { [key: string]: string };
  }) => {
    this.frontel_devices = _frontel_devices;
  };
  getChannelRoles = () => {
    return this.channel_roles;
  };
  setChannelRoles = (_channel_roles: { [key: string]: string[] }) => {
    this.channel_roles = _channel_roles;
  };

  getUsername = () => {
    return this.username;
  };
  getUserID = () => {
    return this.user_id;
  };

  getFirstLastname = () => {
    var ret = this.firstname + " " + this.lastname;

    return ret;
  };
  getAvatar = () => {
    var ret = this.firstname.substr(0, 1) + this.lastname.substr(0, 1);

    return ret.toUpperCase();
  };
  getToken = () => {
    const access_token = localStorage.getItem("access_token")!;
    return access_token;
  };
  getAuthorisedToken = async () => {
    try {
      const access_token = localStorage.getItem("access_token")!;
      if (access_token) {
        if (
          JSON.parse(atob(access_token.split(".")[1])).exp <=
          Math.floor(Date.now() / 1000 + 120)
        ) {
          console.log("Refreshing TOKEN");
          var refresh_token = localStorage.getItem("refresh_token");
          if (refresh_token) {
            var authtokens = await this.functionService?.refreshAccessToken(
              refresh_token,
            );
            if (authtokens) {
              if (authtokens.success) {
                console.log("Refreshing TOKEN  SUCCESS");
                localStorage.setItem("access_token", authtokens.access_token);
                this.isAuthorised = true;
                this.decodeAndStore(localStorage.getItem("access_token")!);
                return this.isAuthorised ? authtokens.access_token : "";
              } else {
                console.log("Refreshing TOKEN  FAILED");
                localStorage.setItem("access_token", "");
                this.isAuthorised = false;
                this.signOut();
                return "";
              }
            } else {
              console.log("NO AUTH TOKENS FROM REFRESH");
              localStorage.setItem("access_token", "");
              this.isAuthorised = false;
              this.signOut();
              return "";
            }
          } else {
            console.log("NO REFRESH TOKEN");
            this.signOut();
            return "";
          }
        } else {
          this.isAuthorised = true;
          this.decodeAndStore(localStorage.getItem("access_token")!);
          return this.isAuthorised ? access_token : "";
        }
      } else {
        this.signOut();
        return "";
      }
    } catch (e) {
      this.signOut();
      return "";
    }
  };

  decodeAndStore(token: string): void {
    try {
      var decrypt: Token = this.parseJwt(token) as Token;
      this.firstname = JSON.parse(
        atob(token.split(".")[1]),
      ).identity.first_name;
      this.channel_roles = decrypt?.user_claims?.roles!;
      this.lastname = JSON.parse(atob(token.split(".")[1])).identity.last_name;
      this.username = JSON.parse(atob(token.split(".")[1])).identity.username;
      this.user_id = JSON.parse(atob(token.split(".")[1])).identity.user_id;
    } catch (e) {
      console.log("Error decrypting:" + e);
    }
  }
  parseJwt(token: string) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
  constructor() {
    this.isAuthorised = false;
    this.functionService = null;
    this.message = "Sign In";
    this.channel_ids = [];
    this.frontel_site_details = {};
    this.frontel_devices = {};
    this.channel_roles = {};
    this.channel_sites = {};
    this.channel_sensors = {};
    this.channels = {};
    this.firstname = "";
    this.lastname = "";
    this.username = "";
    this.user_id = 0;
    this.getAuthorisedToken();
    this.handler = null;
  }

  setFunctionService(functionService: FunctionService) {
    this.functionService = functionService;
  }
}
