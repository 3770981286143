import React from "react";
import Button from "@material-ui/core/Button";
import { AppStore } from "../../../stores/AppStore";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../styling/global/global";
interface MessageModalProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  message: string;
  currenttheme: string;
}
const MessageModal: React.FC<MessageModalProps> = ({
  currenttheme,
  message,
  open,
  onClose,
  appStore,
}: MessageModalProps) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className={classes.message_container}>
        <div
          className={classes.bold_text}
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
        <div className={classes.button_container_small}>
          <Button variant="contained" className="save" onClick={handleClose}>
            OK
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default MessageModal;
