import React, { useState, useEffect } from "react";
import { useStyles } from "../../styling/global/global";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { AppStore } from "../../../stores/AppStore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Sensor } from "../../../services/FunctionService";
interface SiteHealthDetailProps {
  appStore?: AppStore;
  onClose: () => void;
  open: boolean;
  site_id?: number;
}
const SiteHealthDetail: React.FC<SiteHealthDetailProps> = ({
  site_id,
  open,
  onClose,
  appStore,
}: SiteHealthDetailProps) => {
  const classes = useStyles();
  const [message, setMessage] = React.useState<string>("");
  const [sitehealth, setSiteHealth] = React.useState<{ [key: string]: any }>(
    {},
  );
  const handleClose = () => {
    setSiteHealth({});
    onClose();
  };
  useEffect(() => {
    const fetchData = async () => {
      if (open && site_id) {
        var token = await appStore?.authService.getAuthorisedToken();
        if (token) {
          var health = await appStore?.functionService.getHealthDetailForSite(
            token,
            site_id,
          );
          setSiteHealth(health);
        }
      }
    };
    fetchData();
  }, [open, site_id]);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  var healthitems: React.ReactNode[] = [];
  var sensorsdisplay: React.ReactNode[] = [];
  var siteoverall: React.ReactNode[] = [];
  var huboverall: React.ReactNode[] = [];
  var hubvpn: React.ReactNode[] = [];
  var hubmessage: React.ReactNode[] = [];
  if (sitehealth) {
    for (let key in sitehealth) {
      let value = "";
      const translate = appStore?.functionService.translate(
        "en_ZA",
        "health_keys",
        key,
      );
      if (
        key != "trigger_conf" &&
        key != "sensors" &&
        !key.endsWith("ok") &&
        key != "hub_epoch_secs"
      ) {
        value = sitehealth[key];
      } else if (key.endsWith("ok")) {
        let bool = sitehealth[key];
        if (bool) {
          value = "True";
        } else {
          value = "False";
        }
      } else if (key == "hub_epoch_secs") {
        let timelong = sitehealth[key];
        value = new Date(timelong * 1000).toString();
      }
      if (key != "trigger_conf" && key != "sensors") {
        healthitems.push(
          <StyledTableRow key={key}>
            <TableCell>
              <span className={classes.bold_text}>{translate + " : "}</span>
            </TableCell>
            <TableCell>
              <span className={classes.standard_text}>{value}</span>
            </TableCell>
          </StyledTableRow>,
        );
      }
      if (key === "overall_indicator") {
        siteoverall.push(
          value === "ok" ? (
            <SentimentSatisfiedAltIcon
              className={classes.green}
            ></SentimentSatisfiedAltIcon>
          ) : value === "warning" ? (
            <SentimentDissatisfiedIcon
              className={classes.amber}
            ></SentimentDissatisfiedIcon>
          ) : (
            <SentimentVeryDissatisfiedIcon
              className={classes.red}
            ></SentimentVeryDissatisfiedIcon>
          ),
        );
      }
      if (key === "hub_status") {
        huboverall.push(
          value === "healthy" ? (
            <SentimentSatisfiedAltIcon
              className={classes.green}
            ></SentimentSatisfiedAltIcon>
          ) : value === "unhealthy" ? (
            <SentimentVeryDissatisfiedIcon
              className={classes.red}
            ></SentimentVeryDissatisfiedIcon>
          ) : (
            <SentimentDissatisfiedIcon
              className={classes.amber}
            ></SentimentDissatisfiedIcon>
          ),
        );
      }
      if (key === "hub_vpn_ok") {
        hubvpn.push(
          value === "True" ? (
            <CheckIcon className={classes.green}></CheckIcon>
          ) : (
            <CloseIcon className={classes.red}></CloseIcon>
          ),
        );
      }
      if (key === "hub_msg_ok") {
        hubmessage.push(
          value === "True" ? (
            <CheckIcon className={classes.green}></CheckIcon>
          ) : (
            <CloseIcon className={classes.red}></CloseIcon>
          ),
        );
      }

      if (key == "sensors") {
        let sensors = sitehealth[key];
        if (sensors && sensors.length > 0) {
          sensors.sort((a: Sensor, b: Sensor) =>
            a.sensor_id_str.localeCompare(b.sensor_id_str),
          );
          sensors.forEach((sensor: { [key: string]: any }) => {
            for (let skey in sensor) {
              const translate = appStore?.functionService.translate(
                "en_ZA",
                "health_keys",
                skey,
              );
              let value = "";
              value = sensor[skey];
              sensorsdisplay.push(
                <StyledTableRow key={key}>
                  <TableCell>
                    <span className={classes.bold_text}>
                      {sensor.sensor_id_str}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.bold_text}>
                      {translate + " : "}
                    </span>
                  </TableCell>
                  <TableCell>
                    <span className={classes.standard_text}>
                      {translate == "Active Now" ? value.toString() : value}
                    </span>
                  </TableCell>
                </StyledTableRow>,
              );
            }
          });
        }
      }
    }
  }
  if (healthitems.length > 0) {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle className="add-rule-dialog-header">
          Site Health
        </DialogTitle>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Site Overall</TableCell>
                <TableCell>Hub Overall</TableCell>
                <TableCell>Hub VPN</TableCell>
                <TableCell>Hub Messaging</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{siteoverall}</TableCell>
                <TableCell>{huboverall}</TableCell>
                <TableCell>{hubvpn}</TableCell>
                <TableCell>{hubmessage}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>

          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{healthitems}</TableBody>
          </Table>

          <div className="space"></div>
          <div className="space"></div>
          <div className="space"></div>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sensor</TableCell>
                <TableCell>Property</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{sensorsdisplay}</TableBody>
          </Table>
        </TableContainer>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            <span className={classes.bold_text}>OK</span>
          </Button>
        </div>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle className="add-rule-dialog-header">
          Site Health
        </DialogTitle>
        <div className="wait-container">
          <svg width="48px" height="48px" viewBox="0 0 128 128">
            <g>
              <linearGradient id="linear-gradient">
                <stop offset="0%" stopColor="#ffffff" />
                <stop offset="100%" stopColor="#22305f" />
              </linearGradient>
              <path
                d="M63.85 0A63.85 63.85 0 1 1 0 63.85 63.85 63.85 0 0 1 63.85 0zm.65 19.5a44 44 0 1 1-44 44 44 44 0 0 1 44-44z"
                fill="url(#linear-gradient)"
                fill-rule="evenodd"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 64 64"
                to="360 64 64"
                dur="1080ms"
                repeatCount="indefinite"
              ></animateTransform>
            </g>
          </svg>
        </div>
        <div className={classes.button_container}>
          <Button
            variant="contained"
            className={classes.cancel_button}
            onClick={handleClose}
          >
            <span className={classes.bold_text}>OK</span>
          </Button>
        </div>
      </Dialog>
    );
  }
};
export default SiteHealthDetail;
