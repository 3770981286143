import { Dialog, DialogTitle } from "@material-ui/core";
import React, { FC, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import {
  Alert as AlertBox,
  amendedFrontelVideoQueue,
  frontelVideoQueue,
} from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import MessageModal from "../../modals/MessageModal/MessageModal";
import Button from "@material-ui/core/Button";

const currentTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ebe7dd",
    },
    secondary: {
      main: "#010101",
    },
  },
});

interface FrontelDeviceModalProps {
  loadingImage: boolean;
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  siteDetails: { [key: string]: string };
  devicesList: { [key: string]: string };
  status: number;
}

const FrontelDeviceModal: FC<FrontelDeviceModalProps> = ({
  loadingImage,
  appStore,
  open,
  onClose,
  siteDetails,
  devicesList,
  status,
}: FrontelDeviceModalProps) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const frontelAccount = siteDetails["frontel_account"];
  const frontelPanel = siteDetails["frontel_panel"];
  const frontelIp = siteDetails["frontel_ip"];
  const devices = useRef<{ [key: string]: string }>(devicesList);

  const [message, setMessage] = React.useState("");
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [currentTheme, setCurrentTheme] = React.useState("dark");

  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setMessage("");
    setMessageModalOpen(false);
  };

  const handleDeviceSelection = async (deviceIndexKey: string) => {
    const token = await appStore?.authService.getAuthorisedToken();
    var account: string = frontelAccount;
    var panelSerialNumber = frontelPanel;
    var frontelIpAddress = frontelIp;
    var deviceIndex = deviceIndexKey;
    if (token) {
      let res: string = await appStore?.functionService.triggerFrontelDevice(
        token,
        account,
        panelSerialNumber,
        deviceIndex,
        status,
        frontelIpAddress,
      );
      if (res) {
      } else {
        setMessage("Unable to trigger frontel device");
        openMessageModal();
      }
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => {};
  }, [appStore, open, onClose, alert]);

  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="simple-dialog-title"
      open={open}
      className="edit-dialog"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ebe7dd",
        }}
      >
        <span className={classes.bold_text} style={{ fontSize: 17 }}>
          Display of registered Frontel devices
        </span>
      </DialogTitle>
      {!loadingImage ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#ebe7dd",
            minHeight: 250,
            maxHeight: 550,
            overflowY: "scroll",
          }}
        >
          <div style={{ backgroundColor: "#ebe7dd" }}>
            <List component="nav" aria-label="secondary mailbox folders">
              {Object.keys(devices.current).map((key) => {
                return (
                  <ListItem
                    button
                    key={key}
                    onClick={() => handleDeviceSelection(key)}
                  >
                    <ListItemText primary={`${key}: ${devices.current[key]}`} />
                  </ListItem>
                );
              })}
            </List>
          </div>

          <div
            style={{
              paddingTop: 20,
              paddingRight: 20,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              backgroundColor: "#ebe7dd",
              borderTop: "1px solid grey",
            }}
          >
            <Button
              className={classes.mute_button}
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
          <MessageModal
            currenttheme={currentTheme}
            open={messagemodalopen}
            onClose={closeMessageModal}
            message={message}
          />
        </div>
      ) : (
        <p>Loading</p>
      )}
    </Dialog>
  );
};

export default FrontelDeviceModal;
