import React, { useState, useEffect } from "react";
import { AppStore } from "../stores/AppStore";
import LoginPage from "../shared/containers/LoginPage/LoginPage";
import HomePage from "../shared/containers/HomePage/HomePage";
import { Helpers } from "../services/Helpers";
import { Communication } from "../services/Communication";
import { VersionChecking } from "../shared/HelperFunctions";

interface AppProps {
  appStore?: AppStore;
  helpers: Helpers;
  communication: Communication;
}

const App: React.FC<AppProps> = ({
  communication,
  appStore,
  helpers,
}: AppProps) => {
  var currentpage: React.ReactNode = [];
  const [page, setPage] = useState<React.ReactNode | null>(currentpage);

  useEffect(() => {
    if (appStore?.authService.isCurrentlyAuthorised()) {
      setPage(
        <HomePage
          communication={communication}
          helpers={helpers}
          appStore={appStore}
          authhandler={handler}
        />,
      );
      appStore.functionService.updateLocale();
    } else {
      setPage(<LoginPage appStore={appStore} authhandler={handler} />);
    }
  }, []);

  //receive version from API and check against the current. if different, force a reload
  useEffect(() => {
    try {
      const checkVersionInterval = setInterval(async () => {
        await getVersionResult();
      }, 30000);

      return () => {
        clearInterval(checkVersionInterval);
      };
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getVersionResult = async () => {
    const result = await appStore?.functionService.checkVersion();

    if (result?.min_alert_gui_ver && appStore?.version) {
      var alert_backend = result?.min_alert_gui_ver;
      var alert_front = appStore?.version;
      const valueResult = VersionChecking(alert_front, alert_backend);
      if (valueResult === true) {
        console.log("correct version");
      } else {
        appStore?.helpers.stopTimer();
        appStore?.helpers.stopStatsTimer();
        appStore?.authService.signOut();
        var ev: Event;
        ev = new Event("authupdate");
        handler(ev);
        alert(
          "Your alert web interface version is out of date, click ok to update",
        );
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }
        window.location.reload();
      }
    }
  };

  if (appStore) {
    if (appStore.authService.isCurrentlyAuthorised()) {
      currentpage = (
        <HomePage
          communication={communication}
          helpers={helpers}
          appStore={appStore}
          authhandler={handler}
        />
      );
      appStore.functionService.updateLocale();
    } else {
      currentpage = <LoginPage appStore={appStore} authhandler={handler} />;
    }
  }

  function handler(ev: Event) {
    if (appStore) {
      if (ev.type == "authupdate") {
        if (!appStore.authService.isCurrentlyAuthorised()) {
          appStore.helpers.stopTimer();
          appStore.helpers.stopStatsTimer();
          communication.disconnect();
          setPage(<LoginPage appStore={appStore} authhandler={handler} />);
        } else {
          setPage(
            <HomePage
              communication={communication}
              helpers={helpers}
              appStore={appStore}
              authhandler={handler}
            />,
          );
          appStore.functionService.updateLocale();
        }
      }
    }
  }
  appStore?.authService.setHandler(handler);
  return <div>{page}</div>;
};
export { App };
