import React, { useState, useEffect, FC } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";

import {
  Alert as AlertBox,
  UpdateResult,
} from "../../../../services/FunctionService";
import { useStyles } from "../../../styling/global/global";
import { useAudioRecorder } from "../../../hooks/audio/useAudioRecorder";
import { AppStore } from "../../../../stores/AppStore";
import LoadingSpinner from "../../../spinner/LoadingSpinner";
interface TalkbackRecordingProps {
  appStore?: AppStore;
  alert: AlertBox | undefined;
  onClose: () => void;
}
const TalkbackRecording: FC<TalkbackRecordingProps> = ({
  appStore,
  alert,
  onClose,
}: TalkbackRecordingProps) => {
  const classes = useStyles();
  const [resultFailure, setResultFailure] = useState(false);
  const [failMessage, setFailMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    errorMessage,
  } = useAudioRecorder();

  const handleSaveAudio = async (event: React.MouseEvent<HTMLElement>) => {
    setLoading(true);
    let b64: any = await getDataBlob(audioResult);
    let formatted_b64 = "";
    formatted_b64 = b64.split(",")[1];
    // formatted_b64 = b64.replace("data:audio/mp3;base64,", "");
    // formatted_b64 = b64.replace("data:audio/mp3;;base64,", "");
    const token = await appStore?.authService.getAuthorisedToken();
    if (token) {
      const res: UpdateResult = await appStore?.functionService.talkbackAudio(
        token,
        alert!.sensor_id_str,
        formatted_b64,
      );
      if (res.success) {
        setResultFailure(true);
        setFailMessage("audio recording dispatched");
        setLoading(false);
      } else {
        setResultFailure(true);
        setFailMessage(res.msg);
        setLoading(false);
      }
    }
  };

  async function parseURI(d: any) {
    var reader =
      new FileReader(); /* https://developer.mozilla.org/en-US/docs/Web/API/FileReader */
    reader.readAsDataURL(
      d,
    ); /* https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL */
    return new Promise((res, rej) => {
      /* https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise */
      reader.onload = (e) => {
        /* https://developer.mozilla.org/en-US/docs/Web/API/FileReader/onload */
        if (e.target) {
          res(e.target.result);
        }
      };
    });
  }
  async function getDataBlob(url: any): Promise<string> {
    var res = await fetch(url);
    var blob = await res.blob();
    var uri = await parseURI(blob);
    return uri as string;
  }
  useEffect(() => {
    if (resultFailure) {
      const timeId = setTimeout(() => {
        // After 5 seconds set the show value to false

        setResultFailure(false);
        setFailMessage("");
      }, 3000);

      return () => {
        clearTimeout(timeId);
      };
    }
    // when the component is mounted, the alert is displayed for 3 seconds
  }, [resultFailure]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                width: "20%",
              }}
            >
              <p className={classes.bold_text}>
                Status:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>{status}</span>
              </p>
              {errorMessage && (
                <p className={classes.bold_text}>
                  Error:{" "}
                  <span style={{ color: "grey", marginLeft: 10 }}>
                    {errorMessage}
                  </span>
                </p>
              )}
              <p className={classes.bold_text}>
                Timer:{" "}
                <span style={{ color: "grey", marginLeft: 10 }}>
                  {new Date(timer * 1000).toISOString().substr(11, 8)}
                </span>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "5%",
                justifyContent: "center",
              }}
            ></div>
            <audio controls src={audioResult} />
          </div>
          {resultFailure && (
            <Box mt={2} mb={2} justifyContent="center">
              <Alert
                variant="outlined"
                severity={
                  failMessage === "audio recording dispatched"
                    ? "success"
                    : "error"
                }
                onClose={() => {
                  setResultFailure(false);
                  setFailMessage("");
                }}
              >
                {failMessage}
              </Alert>
            </Box>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: "3%",
              marginRight: "3%",
              width: "80%",
              marginTop: 20,
            }}
          >
            <Button className={classes.menu_button} onClick={() => onClose()}>
              <span className={classes.bold_text}>close</span>
            </Button>

            {status === "idle" && (
              <Button className={classes.menu_button} onClick={startRecording}>
                <span className={classes.bold_text}>start</span>
              </Button>
            )}
            {status === "recording" && (
              <Button className={classes.menu_button} onClick={stopRecording}>
                <span className={classes.bold_text}>stop</span>
              </Button>
            )}
            {/* <Button className={classes.menu_button} onClick={pauseRecording}>
              <span className={classes.bold_text}>Pause</span>
            </Button>  */}
            {/* <Button className={classes.menu_button} onClick={resumeRecording}>
              <span className={classes.bold_text}>Resume</span>
            </Button>  */}
            {audioResult && (
              <Button className={classes.menu_button} onClick={handleSaveAudio}>
                <span className={classes.bold_text}>broadcast</span>
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TalkbackRecording;
