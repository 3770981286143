import { Dialog, DialogTitle } from "@material-ui/core";
import React, { FC, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer as MUIDrawer,
  ListItem,
  List,
  ListItemText,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { Alert as AlertBox } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import { AppStore } from "../../../stores/AppStore";
import TextToSpeech from "./TalkBackComponents/TextToSpeech";
import TalkbackPreCanned from "./TalkBackComponents/TalkbackPreCanned";
import TalkbackRecording from "./TalkBackComponents/TalkbackRecording";

const currentTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ebe7dd",
    },
    secondary: {
      main: "#010101",
    },
  },
});

interface TalkBackModalProps {
  appStore?: AppStore;
  open: boolean;
  onClose: () => void;
  alert: AlertBox | undefined;
}

const TalkBackModal: FC<TalkBackModalProps> = ({
  appStore,
  open,
  onClose,
  alert,
}: TalkBackModalProps) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);

  const itemsList = [
    {
      text: "text-to-speech",
      icon: <InboxIcon />,
      onClick: () => setTabValue(0),
      id: 0,
    },
    {
      text: "pre-recorded audios",
      icon: <MailIcon />,
      onClick: () => setTabValue(1),
      id: 1,
    },
    {
      text: "record audios",
      icon: <MailIcon />,
      onClick: () => setTabValue(2),
      id: 2,
    },
  ];

  useEffect(() => {
    return () => {};
  }, [appStore, open, onClose, alert]);

  return (
    <ThemeProvider theme={currentTheme}>
      <Dialog
        onClose={() => onClose()}
        aria-labelledby="simple-dialog-title"
        open={open}
        className="edit-dialog"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ebe7dd",
          }}
        >
          <span className={classes.bold_text} style={{ fontSize: 17 }}>
            Audio fight back
          </span>
        </DialogTitle>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#ebe7dd",
          }}
        >
          <div
            className={classes.talkbackRoot}
            style={{ backgroundColor: "#ebe7dd" }}
          >
            <List component="nav" aria-label="secondary mailbox folders">
              {itemsList.map((item, index) => {
                return (
                  <ListItem
                    button
                    selected={tabValue === item.id}
                    key={item.text}
                    onClick={item.onClick}
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                );
              })}
            </List>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "75%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {tabValue === 0 ? (
              <>
                <span
                  className={classes.bold_text}
                  style={{ fontSize: 14, marginBottom: 20 }}
                >
                  Text to speech
                </span>
                <TextToSpeech
                  alert={alert}
                  appStore={appStore}
                  onClose={onClose}
                />
              </>
            ) : tabValue === 1 ? (
              <>
                <span
                  className={classes.bold_text}
                  style={{ fontSize: 14, marginBottom: 20 }}
                >
                  Pre-recorded audios
                </span>
                <TalkbackPreCanned
                  alert={alert}
                  appStore={appStore}
                  onClose={onClose}
                />
              </>
            ) : (
              <>
                <span
                  className={classes.bold_text}
                  style={{ fontSize: 14, marginBottom: 20 }}
                >
                  Record audio
                </span>
                <TalkbackRecording
                  alert={alert}
                  appStore={appStore}
                  onClose={onClose}
                />
              </>
            )}
          </div>
        </div>
      </Dialog>
    </ThemeProvider>
  );
};

export default TalkBackModal;
