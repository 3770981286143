import React, { MouseEvent } from "react";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AppStore } from "../../../stores/AppStore";
import { MainMenu } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import MessageModal from "../../modals/MessageModal/MessageModal";
import Button from "@material-ui/core/Button";

interface MockEvent {
  (event: Event): any;
}
interface TopNavBarProps {
  appStore?: AppStore;
  authhandler: MockEvent;
  changeMainMenu: (d: MainMenu) => void;
  mainMenu: MainMenu;
  currenttheme: string;
  setShowHistory: (selected: boolean) => void;
  handleShowStats: () => void;
  beep: boolean;
  showhistory: boolean;
  beepChange: (beeping: boolean) => void;
  message: string;
  serverconnected: boolean;
  notificationsconnected: boolean | undefined;
  confirmselection: boolean;
}

const TopNavBar: React.FC<TopNavBarProps> = ({
  confirmselection,
  currenttheme,
  notificationsconnected,
  serverconnected,
  showhistory,
  message,
  appStore,
  authhandler,
  changeMainMenu,
  mainMenu,
  setShowHistory,
  handleShowStats,
  beep,
  beepChange,
}: TopNavBarProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(true);
  const [messagemodalopen, setMessageModalOpen] = React.useState(false);
  const [modalmessage, setModalMessage] = React.useState("");
  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const openMessageModal = () => {
    setMessageModalOpen(true);
  };
  const closeMessageModal = () => {
    setModalMessage("");
    setMessageModalOpen(false);
  };

  const handleClose = (event: MouseEvent<any>) => {
    var x = event.currentTarget;
    setAnchorEl(null);
    if (x.id === "logout") {
      appStore?.helpers.stopTimer();
      appStore?.helpers.stopStatsTimer();
      appStore?.authService.signOut();
      var ev: Event;
      ev = new Event("authupdate");
      authhandler(ev);
    }
  };
  const handleHistoryChange = (
    event: React.ChangeEvent<any>,
    history: boolean,
  ) => {
    setShowHistory(history);
  };
  const handleBeepChange = () => {
    beepChange(!beep);
  };
  const handleMenuChange = (event: MouseEvent<any>, data: string) => {
    mainMenu.dashboard = false;
    mainMenu.navigator = false;
    mainMenu.reports = false;
    mainMenu.settings = false;

    if (data === "dashboard") {
      mainMenu.dashboard = true;
    }
    if (data === "navigator") {
      mainMenu.navigator = true;
    }
    if (data === "reports") {
      mainMenu.reports = true;
    }
    if (data === "settings") {
      setAnchorEl(null);
      mainMenu.settings = true;
    }
    changeMainMenu(mainMenu);
  };
  if (notifications && !notificationsconnected) {
    setNotifications(false);
  }

  let channel_roles = appStore?.authService.getChannelRoles();
  let roles: string[] = [];

  if (channel_roles && Array.isArray(channel_roles)) {
    roles = channel_roles;
  }

  var web_alert_history = false;
  if (roles.includes("web_alert_history")) {
    web_alert_history = true;
  }

  var refresh: React.ReactNode[] = [];
  //connection to notifcations server came online after offline period
  if (!notifications && notificationsconnected) {
    //setNotifications(true);
    //setModalMessage("Notifications online again, please refresh the page!");
    //openMessageModal();
  }
  const av = appStore?.authService.getAvatar();
  var icons: React.ReactNode[] = [];
  if (serverconnected && beep && !showhistory) {
    icons.push(
      <div className={classes.button_container_small}>
        <CheckCircleIcon fontSize="large" />
        <VolumeUpIcon
          onClick={handleBeepChange}
          fontSize="large"
          className={classes.icon_pointer}
        />
      </div>,
    );
  }
  if (!serverconnected && beep && !showhistory) {
    icons.push(
      <div className={classes.button_container_small}>
        <ReportProblemIcon fontSize="large" color="error" />
        <VolumeUpIcon
          onClick={handleBeepChange}
          fontSize="large"
          className={classes.icon_pointer}
        />
      </div>,
    );
  }
  if (serverconnected && !beep && !showhistory) {
    icons.push(
      <div className={classes.button_container_small}>
        <CheckCircleIcon fontSize="large" />
        <VolumeOffIcon
          onClick={handleBeepChange}
          fontSize="large"
          color="error"
          className={classes.icon_pointer}
        />
      </div>,
    );
  }
  if (!serverconnected && !beep && !showhistory) {
    icons.push(
      <div className={classes.button_container_small}>
        <ReportProblemIcon fontSize="large" color="error" />
        <VolumeOffIcon
          onClick={handleBeepChange}
          fontSize="large"
          color="error"
          className={classes.icon_pointer}
        />
      </div>,
    );
  }
  if (beep && showhistory) {
    icons.push(
      <div className={classes.button_container_small}>
        <VolumeUpIcon
          onClick={handleBeepChange}
          fontSize="large"
          className={classes.pointer}
        />
      </div>,
    );
  }
  if (!beep && showhistory) {
    icons.push(
      <div className={classes.button_container_small}>
        <VolumeOffIcon
          onClick={handleBeepChange}
          fontSize="large"
          color="error"
          className={classes.pointer}
        />
      </div>,
    );
  }
  var warning: React.ReactNode[] = [];
  if (message !== "") {
    warning.push(
      <>
        <ReportProblemIcon fontSize="large" color="error" />
        <Typography className={classes.bold_text_large_white}>
          {message}
        </Typography>
      </>,
    );
  }
  if (confirmselection) {
    warning.push(
      <>
        <Typography className={classes.bold_text_large_red}>
          {"PAUSED! To resume, click Apply on camera selection"}
        </Typography>
      </>,
    );
  }
  if (!notificationsconnected) {
    warning.push(
      <div className={classes.button_container_small}>
        <ReportProblemIcon fontSize="large" color="error" />
        <Typography className={classes.bold_text_large_white}>
          {"Notifications offline."}
        </Typography>
      </div>,
    );
  }
  var stats: React.ReactNode[] = [];
  if (!showhistory) {
    stats.push(
      <>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <MultilineChartIcon
          fontSize="large"
          className={classes.pointer}
          onClick={handleShowStats}
        />
      </>,
    );
  }
  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ zIndex: 1250 }}
    >
      <Toolbar className={classes.toolbaritems}>
        <img className={classes.logo} src="/assets/img/logo.png" alt="" />
        <div className={classes.middle_container}>{warning}</div>
        <div className={classes.top_container}>
          {icons}
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

          <Typography className={classes.bold_text_white}>Live</Typography>
          {web_alert_history ? (
            <Switch
              checked={showhistory}
              onChange={handleHistoryChange}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          ) : null}
          {web_alert_history ? (
            <Typography className={classes.bold_text_white}>History</Typography>
          ) : null}

          {stats}
        </div>
        <Avatar onClick={handleClick} className={classes.pointer}>
          {av}
        </Avatar>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.popmenu}
        >
          <MenuItem id="version">
            <span className={classes.bold_text}>
              sw ver: {appStore?.version}
            </span>
          </MenuItem>
          <MenuItem
            id="settings"
            onClick={(e) => handleMenuChange(e, "settings")}
          >
            <span className={classes.bold_text}>Settings</span>
          </MenuItem>
          <MenuItem id="logout" onClick={handleClose}>
            <span className={classes.bold_text}>Logout</span>
          </MenuItem>
        </Menu>
      </Toolbar>
      <MessageModal
        currenttheme={currenttheme}
        open={messagemodalopen}
        onClose={closeMessageModal}
        message={modalmessage}
      />
    </AppBar>
  );
};

export { TopNavBar };
