import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { AppStore } from "../../../stores/AppStore";
import { AuthResult } from "../../../services/FunctionService";
import { useStyles } from "../../styling/global/global";
import Card from "@material-ui/core/Card";
import { VersionChecking } from "../../HelperFunctions";
interface MockEvent {
  (event: Event): any;
}
interface LoginPageProps {
  appStore?: AppStore;
  authhandler: MockEvent;
}

const LoginPage: React.FC<LoginPageProps> = ({
  appStore,
  authhandler,
}: LoginPageProps) => {
  const classes = useStyles();
  var [message, setMessage] = useState("");
  var [username, setUsername] = useState("");
  var [password, setPassword] = useState("");
  var creds: AuthResult;

  function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        <FormControlLabel
          control={<a href="https://www.deepalert.ai/" />}
          label={
            <div className={classes.bold_text_white}>
              <u
                style={{
                  paddingLeft: 26,
                }}
              >
                www.deepalert.ai
              </u>
            </div>
          }
        />
      </Typography>
    );
  }
  async function handleClicked() {
    const result = await appStore?.functionService.checkVersion();
    // if (result?.min_alert_gui_ver === appStore?.version){
    if (result?.min_alert_gui_ver && appStore?.version) {
      var alert_backend = result?.min_alert_gui_ver;
      var alert_front = appStore?.version;
      const valueResult = VersionChecking(alert_front, alert_backend);
      if (valueResult === true) {
        if (username == "") {
          setMessage("Username cannot be empty!");
        } else if (password == "") {
          setMessage("Password cannot be empty!");
        } else {
          if (appStore) {
            var login = await appStore.authService.signIn(username, password);
            setMessage(appStore.authService.getAuthorisedMessage());
            var ev: Event;
            ev = new Event("authupdate");
            authhandler(ev);
          }
        }
      } else {
        appStore?.helpers.stopTimer();
        appStore?.helpers.stopStatsTimer();
        appStore?.authService.signOut();
        var ev: Event;
        ev = new Event("authupdate");
        authhandler(ev);
        alert(
          "Your alert web interface version is out of date, click ok to update",
        );
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
        }
        window.location.reload();
      }
    }
  }

  function handleUserChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword(e.target.value);
  }
  return (
    <div className={classes.login}>
      <Card className={classes.login_container}>
        <Grid container justify="center">
          <Grid item>
            <img src={"/assets/img/logo_main.png"} alt="Logo" />
            <div className={classes.logo_text_white}>CONTROL ROOM</div>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" className={classes.bold_text_white}>
          {message}
        </Typography>
        <form className="form" noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="username"
            name="email"
            autoComplete="email"
            autoFocus
            value={username}
            onChange={handleUserChange}
            className={classes.bold_text_white}
            InputProps={{
              className: classes.bold_text_white,
              classes: {
                root: classes.bold_text_white_blue_background,
                notchedOutline: classes.bold_text_white_blue_background,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text_white,
              },
              style: { color: "#FFFFFF" },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleClicked();
                ev.preventDefault();
              }
            }}
            InputProps={{
              className: classes.bold_text_white,
              classes: {
                root: classes.bold_text_white_blue_background,
                notchedOutline: classes.bold_text_white_blue_background,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.bold_text_white,
              },
              style: { color: "#FFFFFF" },
            }}
          />
          <Grid
            container
            justify="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Button
                onClick={handleClicked}
                variant="contained"
                className={classes.button_text_white}
              >
                LOGIN
              </Button>
            </Grid>
            <Grid item justify="center">
              <Copyright />
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingTop: 15,
                  paddingLeft: 37,
                }}
              >
                ver. {appStore?.version}
              </span>
            </Grid>
          </Grid>
          {/* <Grid container justify="center">
            <Grid item>
              <Copyright />
              <span style={{color: 'white', textAlign: 'center', paddingTop: 15, paddingLeft: 11}}>ver. {appStore?.version}</span>
            </Grid>
          </Grid> */}
        </form>
      </Card>
    </div>
  );
};

export default LoginPage;
